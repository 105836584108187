<template>
    <div>
        <p class="p-b-5">
            <Button type="success" size="small" class="m-l-5" @click="showDownLoadProgrammeModal = true">下载账单清单</Button>
        </p>
        <Table :loading="loading" stripe :data="list" :columns="tableColumns" @on-selection-change="selectionChange" :height="clientHeight"></Table>

        <Row>
            <i-col span='12'>
                <span style="height: 28px;line-height: 28px;">已选中{{feeitemIds.length}}条</span>
            </i-col>
            <i-col  span='12'>
                <div class="paging_style">
                    <Page
                        size="small"
                        :total="total"
                        :page-size="query.pageSize"
                        show-total
                        show-sizer
                        :page-size-opts="[15,50,100,200,500]"
                        :current="query.pageNumber"
                        @on-change="handlePageChanged"
                        @on-page-size-change="handlePageSizeChanged"></Page>
                </div>
            </i-col>
        </Row>

        <Update ref="updateModal"></Update>

        <Modal v-model="showDownLoadProgrammeModal">
            <div class="workplatform-title" slot="header">请选择下载类型</div>
            <Row>
                <i-col span="24">
                <RadioGroup v-model="downLoadProgramme" vertical>
                    <Radio label="1">
                        <span>全部下载</span>
                    </Radio>
                    <Radio label="2">
                        <span>勾选下载</span>
                    </Radio>
                </RadioGroup>
                </i-col>
            </Row>

            <div slot="footer">
                <Button type="text"  @click="showDownLoadProgrammeModal=false">取消</Button>
                <Button type="primary"  @click="choiceDownLoadProgramme">确认</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate, ParseDateForMonth } from '@/utils/dateFormat'
import { formatMainanceReconcilliationStatus } from '@/utils/tagStatus'
import { downloadFileRequest } from '@/utils/download'

import { getsettlementdetailpage } from '@/api/msp/settlement'
import { confirmfeeitems } from '@/api/statement/bill'

import Update from './Update'

export default {
  components: {
    Update
  },
  props: {
    params: Object
  },
  data () {
    return {
      clientHeight: 0,
      total: 0,
      loading: false,

      query: {
        pageNumber: 1,
        pageSize: 15,
        settlementCompanyId: 0,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        startDate: '',
        endDate: '',
        status: null,
        keyword: ''
      },
      list: [],
      tableColumns: [
        {
          type: 'selection',
          width: 65,
          key: 'id',
          align: 'center',
          fixed: 'left'
        },
        {
          title: '资源编号',
          key: 'resourceCode',
          width: 120,
          fixed: 'left',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceCode)])
          }
        },
        {
          title: '资产',
          key: 'code',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.assetName)])
          }
        },
        {
          title: '位置',
          key: 'companyName',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.stationName)])
          }
        },
        {
          title: '资源类型',
          key: 'startDate',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceTypeName)])
          }
        },
        {
          title: '客户',
          width: 120,
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName',
          width: 120,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.brandName || '-')
            ])
          }
        },

        {
          title: '作业类型',
          key: 'taskTypeName',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.taskTypeName)])
          }
        },
        {
          title: '用途',
          key: 'adTypeName',
          width: 120,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.adTypeName || '-')
            ])
          }
        },
        {
          title: '费用类型',
          width: 120,
          key: 'costTypeName',
          render: (h, params) => {
            return h('div', [
              h('p', params.row.costTypeName || '-')
            ])
          }
        },
        {
          title: '计费数量',
          key: 'chargeQuantity',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.chargeQuantity + params.row.chargeQuantityUnit)])
          }
        },
        {
          title: '单价',
          key: 'unitCost',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.formatUnitCost + '/' + params.row.chargeQuantityUnit)])
          }
        },
        {
          title: '记账金额',
          key: 'amount',
          width: 120,
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '作业日期',
          key: 'actionDate',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.actionDate)])
          }
        },
        {
          title: '作业完成时间',
          key: 'finishTime',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.finishTime)])
          }
        },
        {
          title: '质检时间',
          key: 'qualityTestTime',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.qualityTestTime)])
          }
        },
        // {
        //   title: '可对账',
        //   width: 80,
        //   align: 'center',
        //   render: (h, data) => {
        //     if (data.row.qualityTesting) {
        //       return h('Icon', {
        //         props: { type: 'md-checkbox-outline', size: 16, color: '#19be6b' }
        //       })
        //     } else {
        //       return h('Icon', {
        //         props: { type: 'md-close', size: 16, color: '#ed4014' }
        //       })
        //     }
        //   }
        // },
        {
          title: '备注',
          key: 'remark',
          width: 120,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.remark || '-')
            ])
          }
        },
        {
          title: '状态',
          width: 120,
          render: (h, data) => {
            return formatMainanceReconcilliationStatus(
              h,
              data.row.status,
              data.row.statusName
            )
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          width: 80,
          fixed: 'right',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$refs.updateModal.showModal(params.row)
                    }
                  }
                },
                '详情'
              )
            ])
          }
        }
      ],

      feeitemIds: [], // 页面选中的所有项
      currentPageAllItemIds: [], // 当前操作的页面所有项的主键集合
      currentPageSelectionIdArray: [],
      showDownLoadProgrammeModal: false,
      downLoadProgramme: '1'
    }
  },
  created () {
    this.$store.commit('set_reconciliationDemo_notAllowReconciliationQuantity', 0)
    this.feeitemIds = []
    this.clientHeight = document.documentElement.clientHeight - 180
  },
  computed: {
    maintenanceReconciliationQuery () {
      return this.$store.state.supplierReconciliation.maintenanceReconciliationQuery
    },
    notAllowReconciliationQuantity () {
      return this.$store.state.supplierReconciliation.notAllowReconciliationQuantity || 0
    },
    beginUpdate () {
      return this.$store.state.supplierReconciliation.beginUpdate
    }
  },
  watch: {
    beginUpdate (val) {
      this.$store.commit('set_reconciliationDemo_notAllowReconciliationQuantity', 0)
      this.feeitemIds = []

      if (this.maintenanceReconciliationQuery.supplierCompanyId) {
        this.query.supplierCompanyId = this.maintenanceReconciliationQuery.supplierCompanyId
        this.query.settlementCompanyId = this.maintenanceReconciliationQuery.settlementCompanyId
        this.query.startDate = this.maintenanceReconciliationQuery.startDate
        this.query.endDate = this.maintenanceReconciliationQuery.endDate
        this.query.startActionDate = this.maintenanceReconciliationQuery.startActionDate
        this.query.endActionDate = this.maintenanceReconciliationQuery.endActionDate
        this.query.keyword = this.maintenanceReconciliationQuery.keyword
        this.query.minCost = this.maintenanceReconciliationQuery.minCost
        this.query.maxCost = this.maintenanceReconciliationQuery.maxCost
        this.query.taskMainTypeFlag = this.maintenanceReconciliationQuery.taskMainTypeFlag
        this.query.status = this.maintenanceReconciliationQuery.status === -1 ? null : this.maintenanceReconciliationQuery.status
        this.query.taskitemTypes = this.maintenanceReconciliationQuery.taskitemTypes
        this.query.resourcetypeIds = this.maintenanceReconciliationQuery.resourcetypeIds
      }
      this.reloadPage()
    }

  },
  methods: {
    reloadPage () {
      this.query.pageNumber = 1
      if (this.query.supplierCompanyId) {
        this.loadPage()
      }
    },
    loadPage () {
      this.loading = true
      const that = this
      getsettlementdetailpage(that.query).then((res) => {
        if (res.list.length) {
          res.list.map((item) => {
            item.visible = item.visibleScope === 2
            item._checked = that.feeitemIds.includes(item.id)
            item.formatUnitCost = toMoney(item.unitCost)
            // item._disabled = (item.status === 2)
            return item
          })
          that.currentPageAllItemIds = res.list.map(item => {
            return item.id
          })
        }

        that.list = res.list
        that.total = res.totalRow
        that.loading = false
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.loadPage()
    },
    handlePageSizeChanged (pagesize) {
      this.query.pageSize = pagesize
      this.reloadPage()
    },
    selectionChange (selection) {
      const that = this
      var quantity = that.notAllowReconciliationQuantity
      const selectionIds = selection.map(item => {
        return item.id
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach(item => {
        // 列是否是选中状态
        const currentItem = that.list.filter(x => x.id === item)[0]
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.feeitemIds.includes(item)) {
            that.feeitemIds.push(item)
            if (!currentItem.qualityTesting || currentItem.status === 2) {
              quantity = quantity + 1
            }
          }
        } else { // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.feeitemIds.includes(item)) {
            that.feeitemIds.splice(that.feeitemIds.indexOf(item), 1)
            if (!currentItem.qualityTesting || currentItem.status === 2) {
              quantity = quantity - 1
            }
          }
        }
      })

      this.$store.commit('set_reconciliationDemo_notAllowReconciliationQuantity', quantity)
    },
    confirmReconcilliation () {
      const that = this
      if (!that.feeitemIds.length) {
        that.$Notice.info({ desc: '请选择要对账的条目！' })
      } else {
        that.$Modal.confirm({
          title: '操作确认',
          content: '<p>是否要将所选条目标记为已对账，确认后<span style="color:red;font-weight:600;">无法再次变更</span>，请谨慎操作！</p>',
          loading: true,
          onOk: () => {
            confirmfeeitems({
              feeitemListJsonStr: JSON.stringify(that.feeitemIds),
              feeitemType: 2
            }).then((res) => {
              if (res && !res.errcode) {
                this.$Notice.success({ desc: '成功提交对账！' })
                that.feeitemIds = []
                that.$store.commit('set_reconciliationDemo_update', new Date())
              }
              that.$Modal.remove()
            }).catch(() => {
              that.$Modal.remove()
            })
          }
        })
      }
    },
    choiceDownLoadProgramme () {
      const that = this
      const params = {
        taskMainTypeFlag: that.maintenanceReconciliationQuery.taskMainTypeFlag
      }
      if (parseInt(that.downLoadProgramme) === 1) {
        params.publisherId = that.query.publisherId
        params.supplierCompanyId = that.maintenanceReconciliationQuery.supplierCompanyId === '-1' ? null : that.maintenanceReconciliationQuery.supplierCompanyId
        params.settlementCompanyId = that.maintenanceReconciliationQuery.settlementCompanyId === '-1' ? null : that.maintenanceReconciliationQuery.settlementCompanyId
        params.startDate = that.maintenanceReconciliationQuery.startDate
        params.endDate = that.maintenanceReconciliationQuery.endDate
        params.startActionDate = that.maintenanceReconciliationQuery.startActionDate
        params.endActionDate = that.maintenanceReconciliationQuery.endActionDate
        params.keyword = that.maintenanceReconciliationQuery.keyword
        params.minCost = that.maintenanceReconciliationQuery.minCost
        params.maxCost = that.maintenanceReconciliationQuery.maxCost
        params.status = that.maintenanceReconciliationQuery.status === -1 ? null : that.maintenanceReconciliationQuery.status

        params.taskitemTypes = that.maintenanceReconciliationQuery.taskitemTypes
        params.resourcetypeIds = that.maintenanceReconciliationQuery.resourcetypeIds
      } else {
        if (!that.feeitemIds.length) {
          that.$Notice.info({ desc: '请选择要下载的条目！' })
          return
        } else {
          params.taskitemChargeIds = JSON.stringify(that.feeitemIds)
        }
      }

      that.downLoadExecl(params)
    },
    // 下载列表
    downLoadExecl (params) {
      let dateStr = ParseDate(new Date())
      // 全部下载
      if (parseInt(this.downLoadProgramme) === 1) {
        if (params.startDate && params.endDate) {
          dateStr = ParseDateForMonth(params.startDate) + '至' + ParseDateForMonth(params.endDate)
        }
      }
      const filename = '运维对账明细(' + dateStr + ').xlsx'
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
            '/ooh-msp/v2/msppreport/downloadsettlementdetail',
        params,
        filename
      )
    }
  }
}
</script>
